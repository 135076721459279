@use 'sass:map';
@use '../../constants';

@mixin theme($theme) {
  $is-dark-theme: map.get($theme, is-dark);

  .content {
    .lightbox {
      border: 1px solid if($is-dark-theme, constants.$darkgray, constants.$lightgray);
      background-color: if($is-dark-theme, constants.$deepgray, constants.$lightboxgray);

      img {
        background-color: constants.$white;
      }
    }
  }
}
