@use 'sass:map';
@use '../../constants';

@mixin theme($theme) {
  $is-dark-theme: map.get($theme, is-dark);

  .alert {
    color: if($is-dark-theme, constants.$offwhite, constants.$darkgray);

    &.is-critical {
      border-left: 8px solid constants.$brightred;
      background-color: if($is-dark-theme, constants.$deepgray, rgba(constants.$brightred, 0.05));

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: constants.$brightred;
      }
    }

    &.is-important {
      border-left: 8px solid constants.$orange;
      background-color: if($is-dark-theme, constants.$deepgray, rgba(constants.$orange, 0.05));

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: constants.$orange;
      }
    }

    &.is-helpful {
      border-left: 8px solid constants.$blue;
      background-color: if($is-dark-theme, constants.$deepgray, rgba(constants.$blue, 0.05));

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: constants.$blue;
      }
    }

    &.archive-warning {
      background-color: if($is-dark-theme, constants.$deepgray, constants.$darkred);

      * {
        color: constants.$white;
      }

      a {
        color: constants.$white;
      }
    }
  }
}
